import React, { useEffect, useState } from "react";
import useToken from "../auth/useToken";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Box,
  CircularProgress,
  Typography,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Contact {
  contactid: string;
  firstname?: string;
  lastname?: string;
  fullname?: string;
  emailaddress1?: string;
  telephone1?: string;
  permissions?: string[];
}

const ContactTable: React.FC = () => {
  const getToken = useToken();
  const navigate = useNavigate();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        // Fetch all contacts
        const token = await getToken();
        const response = await fetch(
          "https://kyc-poc.ntegra.co.uk/api/kyc/fetch",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();

        // For each contact, fetch the current user permissions
        const contactsWithPermissions: Contact[] = await Promise.all(
          data.value.map(async (contact: Contact) => {
            try {
              const permissionsResponse = await fetch(
                `https://kyc-poc.ntegra.co.uk/api/kyc/fetch-permissions/${contact.contactid}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              if (!permissionsResponse.ok) {
                throw new Error(
                  `Error: ${permissionsResponse.status} ${permissionsResponse.statusText}`
                );
              }

              const permissionsData = await permissionsResponse.json();
              return { ...contact, permissions: permissionsData.permissions };
            } catch (permissionsError) {
              console.error(
                `Failed to fetch permissions for contact ${contact.contactid}`,
                permissionsError
              );
              return {
                ...contact,
                permissions: ["Error fetching permissions"],
              };
            }
          })
        );

        setContacts(contactsWithPermissions);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  if (loading)
    return (
      <Box sx={{ display: "flex", flex: 1 }}>
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    );

  const handleRowClick = (contactid: string) => {
    navigate(`/contacts/${contactid}`);
  };

  return (
    <>
      <Typography
        variant="h5"
        style={{
          marginBottom: 15,
        }}
      >
        KYC Contacts
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>First Name</b>
              </TableCell>
              <TableCell>
                <b>Last Name</b>
              </TableCell>
              <TableCell>
                <b>Full Name</b>
              </TableCell>
              <TableCell>
                <b>Email Address</b>
              </TableCell>
              <TableCell>
                <b>Phone Number</b>
              </TableCell>
              <TableCell>
                <b>Permissions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((contact: Contact) => (
              <TableRow
                key={contact.contactid}
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => handleRowClick(contact.contactid)}
              >
                <TableCell>{contact.firstname || "N/A"}</TableCell>
                <TableCell>{contact.lastname || "N/A"}</TableCell>
                <TableCell>{contact.fullname || "N/A"}</TableCell>
                <TableCell>{contact.emailaddress1 || "N/A"}</TableCell>
                <TableCell>{contact.telephone1 || "N/A"}</TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {contact.permissions && contact.permissions?.length > 0 ? (
                      contact.permissions?.map((permission, index) => (
                        <Chip key={index} label={permission} color="success" />
                      ))
                    ) : (
                      <Chip key={1} label="No permissions" color="error" />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ContactTable;
