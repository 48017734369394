import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Chip, Paper, Stack, Typography } from "@mui/material";

interface ProfilePageProps {
  claims: any;
}

export default function ProfilePage({ claims }: ProfilePageProps) {
  return (
    <>
      <Typography
        variant="h5"
        style={{
          marginBottom: 15,
        }}
      >
        ID Token Claims
      </Typography>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Claim</b>
              </TableCell>
              <TableCell>
                <b>Claim Value</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claims &&
              Object.keys(claims).map(function (key) {
                return (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{key}</TableCell>
                    <TableCell>
                      {Array.isArray(claims[key]) ? (
                        <Stack direction="row" spacing={1}>
                          {claims[key].map((value: string) => (
                            <Chip label={value} color="secondary" />
                          ))}
                        </Stack>
                      ) : (
                        claims[key]
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
