import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    clientId: "b55a7311-dbca-4274-8350-2eae30c84393", // Application (client) ID from Entra
    authority: "https://login.microsoftonline.com/f3b2923a-699f-4fea-8c99-afc54fc3e2f3", // Tenant ID from Entra
    redirectUri: "https://kyc-poc.ntegra.co.uk", // URL to redirect to after login
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
    secureCookies: true
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["api://e2ac1df5-16af-4bc9-8a54-f0aa00022e99/kyc.api"]
};
