import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./msalConfig";

const useToken = () => {
  const { instance, accounts } = useMsal();
  const account = accounts[0];

  const getToken = async (): Promise<string | null> => {
    if (!account) return null;

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      return response.accessToken;
    } catch (error) {
      console.error("Silent token acquisition failed; using popup", error);
      const response = await instance.acquireTokenPopup(loginRequest);
      return response.accessToken;
    }
  };

  return getToken;
};

export default useToken;
