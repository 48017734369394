import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import useToken from "../auth/useToken";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, Typography } from "@mui/material";

export default function ContactPage() {
  const getToken = useToken();
  const [data, setData] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    try {
      if (!id) {
        setError("ID parameter is missing.");
        return;
      }

      const token = await getToken();
      if (!token) throw new Error("Failed to acquire token.");

      const response = await fetch(`/api/kyc/fetch/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err: any) {
      setError(err.message);
    }
  }, [getToken, id]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Typography
        variant="h5"
        style={{
          marginBottom: 15,
        }}
      >
        KYC Contact - {id}
      </Typography>
      {error ? (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      ) : data ? (
        <div>
          <h2>Data from API:</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      ) : (
        <Box sx={{ display: "flex", flex: 1 }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
