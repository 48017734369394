import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#01073a",
    },
    secondary: {
      main: "#ae1d57",
    },
  },
  typography: {
    fontFamily: `agenda,sans-serif`,
  },
});

export default theme;
