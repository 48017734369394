import * as React from 'react';
import Button from '@mui/material/Button';

interface LoginPageProps {
  onLogin: () => void;
}

export default function LoginPage({ onLogin }: LoginPageProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20%' }}>
      <h2>Login</h2>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={onLogin}
        sx={{ marginTop: 2 }}
      >
        Login
      </Button>
    </div>
  );
}
