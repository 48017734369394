import React from "react";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
} from "@azure/msal-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthProvider from "./auth/AuthProvider";
import { loginRequest } from "./auth/msalConfig";
import AppBar from "./components/AppBar";
import LeftNav from "./components/LeftNav";
import Box from "@mui/material/Box";
import ProfilePage from "./pages/ProfilePage";
import ContactsPage from "./pages/ContactsPage";
import ContactPage from "./pages/ContactPage";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import LoginPage from "./pages/LoginPage";
import CssBaseline from "@mui/material/CssBaseline";

const App: React.FC = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .catch((error) => console.error("Login failed:", error));
  };

  const handleLogout = () => {
    instance.logout().catch((error) => console.error("Logout failed:", error));
  };

  const profile = {
    email: account?.username || "",
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthenticatedTemplate>
          <AppBar onLogout={handleLogout} profile={profile} />
          <Box sx={{ display: "flex" }}>
            <LeftNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Routes>
                <Route
                  path="/"
                  element={<ProfilePage claims={account?.idTokenClaims} />}
                />
                <Route path="/contacts" element={<ContactsPage />} />
                <Route path="/contacts/:id" element={<ContactPage />} />
              </Routes>
            </Box>
          </Box>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage onLogin={handleLogin} />
        </UnauthenticatedTemplate>
      </Router>
    </ThemeProvider>
  );
};

const RootApp: React.FC = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default RootApp;
