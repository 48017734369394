import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SecurityIcon from '@mui/icons-material/Security';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

// Define the list items with label, icon, and path
const navItems = [
  { label: 'Profile', icon: <AccountCircleIcon />, path: '/' },
  { label: 'Contacts', icon: <SecurityIcon />, path: '/contacts' }
  // { label: 'Contact - Ron Kane', icon: <SecurityIcon />, path: '/contact?id=c0db8c3e-1fb3-ef11-b8e8-6045bd0c7e5b' }, // Ron
  // { label: 'Contact - Louis Stones', icon: <SecurityIcon />, path: '/contact?id=d910beba-c3b3-ef11-b8e8-6045bd0c7e5b' } // Louis
];

export default function LeftNav() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
      PaperProps={{
        style: {
          top: 64,
        },
      }}
    >
      <Box>
        <List>
          {navItems.map((item) => (
            <ListItem key={item.label} disablePadding>
              <ListItemButton component={Link} to={item.path}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
