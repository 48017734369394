import React, { ReactNode } from "react";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./msalConfig";

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => (
  <MsalProvider instance={msalInstance}>{children}</MsalProvider>
);

export default AuthProvider;
